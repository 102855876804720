import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";

// eslint-disable-next-line
export default class NewsList extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const { currentPage, numPages } = this.props.pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;

    return (
      <Layout>
        <Helmet>
          <title>News | Pathlab</title>
          <meta
            name="description"
            content="Read the latest news from Pathlab."
          />
        </Helmet>
        <section className="section">
          <div className="container content-border">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="content">
                  <h1 className="has-text-weight-bold is-size-2">News</h1>
                </div>
                {posts.map(({ node: post }) => (
                  <div className="content basic-border news-item" key={post.id}>
                    <p>
                      <Link
                        className="has-text-primary news-header"
                        to={post.fields.slug}
                      >
                        <strong>{post.frontmatter.title}</strong>
                      </Link>
                      <br />
                      <small>{post.frontmatter.date}</small>
                    </p>
                    <p>
                      {post.excerpt}
                      <br />
                      <br />
                      <Link
                        className="button is-small is-primary"
                        to={post.fields.slug}
                      >
                        Keep Reading →
                      </Link>
                    </p>
                  </div>
                ))}

                <ul className="pagination">
                  {!isFirst && (
                    <Link
                      to={`/news/${currentPage === 2 ? "" : currentPage - 1}`}
                      className="has-text-primary"
                    >
                      Back
                    </Link>
                  )}
                  {Array.from({ length: numPages }, (_, i) => (
                    <li
                      key={`pagination-number${i + 1}`}
                      style={{
                        margin: 0,
                      }}
                    >
                      <Link
                        to={`/news/${i === 0 ? "" : i + 1}`}
                        style={{
                          padding: "1em",
                          textDecoration: "none",
                        }}
                        className={
                          i + 1 === currentPage
                            ? "active-pagination"
                            : "has-text-primary"
                        }
                      >
                        {i + 1}
                      </Link>
                    </li>
                  ))}
                  {!isLast && (
                    <Link
                      to={`/news/${currentPage + 1}`}
                      className="has-text-primary"
                    >
                      Next
                    </Link>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

NewsList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export const newsListQuery = graphql`
  query newsListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { templateKey: { eq: "news-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
